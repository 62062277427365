.pg-PresetGroupTemplate {
  cursor: move;
  position: relative;
  width: 160px;
  height: 200px;
  border: 1px solid #f5f5f5;
  box-shadow: 0 10px 10px 2px rgb(0 0 0 / 5%);
  overflow: hidden;
  padding: 12px;
  text-align: center;
  word-break: break-all;
  flex-shrink: 0;
  z-index: 9;
  background-color: white;

  &__Title {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    right: 0;
    padding: 0.5rem;
    background: white;
    font-size: 0.8rem;
    margin: 0;
    transform: translateY(-50%);
    text-align: center;
    z-index: 5;
    font-weight: bold;
    border: 1px solid #f5f5f5;
    word-break: break-word;
  }

  &__Placeholder {
    filter: blur(6px);
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #999;
    user-select: none;
    pointer-events: none;
  }

  &__Detach {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    visibility: hidden;
    pointer-events: all;

    button {
      color: #999 !important;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    &:hover {
      button {
        color: var(--bs-danger) !important;
      }
    }
  }

  &:hover {
    .pg-PresetGroupTemplate__Detach {
      visibility: visible;
    }
  }
}
