.pg-TableHeader {
  font-weight: 600;
  text-transform: uppercase;
  color: #6b778c;

  &--align-right,
  &--action {
    text-align: right;
  }
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #dee2e6;
}
