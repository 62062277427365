@import '../../../../styles/variables.scss';

.pg-UploadedDocument {
  background: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 15px 20px;
  color: $secondary;
  margin-bottom: 2rem;

  &__Icon {
    font-size: 24px;
    margin-right: 10px;
  }

  &__Content {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__Title {
    font-weight: bold;
  }

  &__Actions {
    text-decoration: underline;

    a + a {
      margin-left: 2rem;
    }
  }
}
