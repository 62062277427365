.pg-ActiveContract {
  .pg-RouterTabs__Items {
    margin-left: -30px;
  }

  &__Signers,
  &__Details {
    max-width: 800px;
  }

  &__Header {
    padding-bottom: 30px;
  }

  &Signer {
    margin: 20px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }

    &__Name {
      margin-bottom: 0.25rem;
      margin-right: 0.5rem;
    }

    &__Company,
    &__Email {
      color: #777;
    }

    &__SignedBy {
      margin-bottom: 15px;
      display: block;
    }

    &__RightContent {
      padding-bottom: 10px;
    }
  }

  &Invoices {
    &__Content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__TotalCount {
      font-weight: bold;
      font-size: 1.5rem;
    }

    &__TotalLabel {
      color: #888;
    }

    &__Details {
      padding-top: 2rem;
      margin-top: 2rem;
      border-top: 2px dashed #eee;
    }

    &__InvoiceItem {
      border-radius: 4px;
      padding: 1rem;
      margin-bottom: 0.5rem;
      border: 1px solid #ddd;
      margin: 30px auto;

      &__Header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding: 0 0 1rem;
        margin: 0 0 1rem;
      }

      &__Total {
        font-size: 18px;
        font-weight: bold;
      }
    }

    &__InvoiceItemSection {
      display: flex;
      justify-content: space-between;
    }

    &__InvoiceItemLabel {
      font-weight: bold;
    }

    &__InvoiceItemValue {}
  }
}

.pg-InvoiceItemInfo {
  &:not(:first-of-type) {
    padding-top: 20px;
  }

  &:not(:last-of-type) {
    padding-bottom: 20px;
  }

  &:not(:last-child) {
    border-bottom: 1px dashed #ddd;
  }

  &__Info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    b {
      display: inline-block;
      min-width: 120px;

      &:after {
        content: ':';
      }
    }
  }

  &__Input {
    display: flex;

    .pg-Button {
      flex: 0 0 120px;
    }
  }
}