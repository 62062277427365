.pg-OnboardingLayout {
  display: flex;
  flex-direction: column;

  .pg-Onboarding__Steps {
    padding: 1rem 0 0;

    @media screen and (max-width: 1180px) {
      display: none;
    }
  }

  .pg-Onboarding__Header .pg-Onboarding__Steps {
    display: none;

    @media screen and (max-width: 1180px) {
      display: flex;
    }
  }

  .pg-StepItem {
    padding: 0;
    margin: 1rem 4.5rem;
    margin-left: 0;

    &:not(:last-child) .pg-StepItem__Icon::after {
      height: 3rem;
    }
  }

  &__Content {
    display: flex;
  }

  &__Body {
    flex: 1;
    border-left: 1px solid #e1e3eb;
    padding: 2rem 0 0 2rem;
    max-width: 100%;

    @media screen and (max-width: 1180px) {
      border-left: none;
      padding: 2rem 0 0;
    }
  }

  &__Header {
    border-bottom: 1px solid #e1e3eb;
  }
}