.pg-LabelValueItem {
  margin-bottom: 15px;

  &:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  &__Label {
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
  }
  &__Value {
    margin-bottom: 15px;
  }
}
