.pg-ExtrasContainer {
  padding: 30px;
  // width: calc(100vw - 600px);
}
.pg-Extras__Item {
  $scope: &;
  width: 100%;

  .pg-MediaObject__Figure svg {
    font-size: 28px;
    color: #757575;
  }

  &__Link {
    padding: 0.375rem 0;
  }

  &Container {
    // width: 41.66667%;
    align-items: center;
    display: flex;
    h6 {
      display: flex;
      color: #091e42;

      svg {
        display: block;
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #dfe1e6;
    }

    &:first-child {
      padding-top: 0;
    }
  }
}

.pg-Extras__Border {
  border-bottom: 1px solid #dfe1e6;
  padding: 0 0 30px;
  margin-bottom: 30px;
}
