.bp3-popover2-arrow svg {
  vertical-align: top;
}

.bp3-tooltip2 .bp3-popover2-content {
  background: $secondary;
}

.bp3-tooltip2 .bp3-popover2-arrow-fill,
.bp3-tooltip2 .bp3-popover2-arrow-border {
  fill: $secondary;
}

span[class$='-indicatorSeparator'] {
  display: none;
}

.form-control {
  line-height: 1.8215;
}
