@import '../../styles/variables';

.pg-Modal {
  background: rgba(0, 0, 0, 0.1);
  overflow: auto !important;
  // padding-left: $sidebar-width;

  .modal-header {
    align-items: flex-start;
    border-bottom: none;
    background: #fefefe;

    h4 {
      margin-bottom: 0;
    }
  }

  .modal-footer {
    border-top: none;
    background: #fafafa;
    border-radius: 0;
  }

  &__Dialog {
    display: flex;
    width: 100%;
  }

  &__Content {
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0px 50px 80px 0px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 4px;
  }

  &__CloseBtn {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
  }
}
