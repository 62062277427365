.pg-ContractListItem {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;

  &__Media {
    .pg-MediaObject__Body {
      overflow: hidden;
    }
  }

  &:first-child {
    border-top: none;
  }

  &__Link {
    display: flex;
    flex: 1;
    color: #333;
    text-decoration: none;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    overflow: hidden;

    &:hover {
      color: #333;
      text-decoration: none;
    }
  }

  &__BasicInfo {
    overflow: hidden;
  }

  &__Actions {
    min-width: 120px;
    text-align: center;
  }

  &__NameContainer {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
  }

  &__Name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__Contractor {
    font-size: 14px;
    color: #757575;
  }

  &__RateInfo {
    text-align: right;
    font-size: 14px;
    white-space: nowrap;
    margin-left: 30px;
  }

  &__Rate {
    font-weight: bold;
  }

  &__Type {
    margin-left: 20px;
  }

  &__RightContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__Status {
    min-width: 190px;
    text-align: right;
  }
}
