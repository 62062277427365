.pg-RoleList {
  margin: 0 -1rem -1rem;
  &Item {
    display: flex;
    padding: 20px;
    border-top: 1px solid #ddd;

    &__Radio {
    }
    &__Name {
      min-width: 200px;
    }
    &__Description {
    }
  }
}
