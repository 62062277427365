.pg-DatePicker {
  &__YearMonthForm {
    display: flex;
  }

  &__YearSelect {
    flex-basis: 85px;
    border-radius: 0 0.25rem 0.25rem 0;
  }
  &__MonthSelect {
    flex-basis: calc(100% - 85px);
    border-radius: 0.25rem 0 0 0.25rem;
  }
}

.DayPicker-Day {
  padding: 0.5em 0.7em;
}

.DayPickerInput-Overlay::after {
  content: '';
  display: block;
  width: 100%;
  height: 3rem;
  position: absolute;
}
