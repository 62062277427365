.pg-Accept-invitation {
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		width: 50%;
		padding: 2rem;
		min-width: 300px;
		max-width: 600px;
		margin-bottom: 2rem;
	}

	&__text {
		text-align: center;
		color: #999;
		font-size: 18px;
	}
}