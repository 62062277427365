.pg-Signer {
  &__Content {
    border-radius: 4px;
    padding: 20px;
    margin: 0 0 20px;
    border: 1px solid #ddd;
  }

  &__Action {
    text-align: right;
  }
}
