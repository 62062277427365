.pg-OnboardingHeader {
  padding: 0 0 1.5rem;

  &__Title {
    font-weight: 600;
    margin-bottom: 0.25rem;
    color: #1a284d;
  }

  &--large &__Title {
    font-size: 1.5rem;
  }

  &--medium &__Title {
    font-size: 1.15rem;
  }

  &--small &__Title {
    font-size: 1rem;
  }

  &__Subtitle {
    color: #546285;
    font-weight: 400;
  }
}
