.placeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-label {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
