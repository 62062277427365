.pg-Back__Button {
  text-decoration: none;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
  width: 80px;
  width: fit-content;
  padding: 0.5rem 0;
  color: #999;
  margin-left: -6px;
}
