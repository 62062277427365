@import '../../styles/variables.scss';

.pg-CompanySidebarSelect {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #313d7d;
  position: relative;
  border-radius: 4px;

  &__Title {
    display: block;
    padding: 6px 15px 0;
    font-size: 10px;
    text-transform: uppercase;
    opacity: 0.7;

    @media screen and (min-width: 426px) and (max-width: 768px) {
      display: none;
      padding: .25rem;
    }
  }

  &__Selected {
    padding: 0 15px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;


    @media screen and (min-width: 426px) and (max-width: 768px) {
      padding: 5px;
    }

    span {
      display: inline-block;
      max-width: calc(100% - 40px);
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (min-width: 426px) and (max-width: 768px) {
        max-width: 100%;
        width: 100%;
        text-align: center;
      }

    }

    &+svg {
      @media screen and (min-width: 426px) and (max-width: 768px) {
        display: none;
      }
    }
  }

  &__Item {
    text-decoration: none;
    display: block;
    margin: 0 !important;
    padding: 10px 20px;
    border: none;
    width: 100%;
    text-align: left;
  }

  .pg-Dropdown__Target {
    cursor: pointer;
  }

  .pg-Dropdown__Content {
    width: calc(100%);
    margin-left: 0px;
    padding: 0;
  }

  .pg-Dropdown__Target>svg {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__Create {
    display: flex;
    align-items: center;

    svg {
      font-size: 24px;
      margin-right: 10px;
    }
  }

  &__CreateItem {
    box-shadow: 0 0 15px -10px rgba(#000, 0.75);
    position: sticky;
    bottom: 0;
    background: #fff;
    border-radius: 4px;

    &:disabled {
      opacity: 1;
      pointer-events: auto;
    }

    svg {
      font-size: 24px;
      margin-right: 10px;
    }
  }

  &__CreateLink {
    color: #fff;
    font-size: 14px;
    padding: 0.75rem;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    text-decoration: none;
    min-height: unset;
    margin: 0 auto;
    width: 100%;

    &:hover {
      color: #fff;
    }

    & .pg-Button__Content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      font-size: 24px;
      margin-right: 10px;
    }
  }

  .pg-Sidebar__Companies {
    overflow: auto;
    max-height: 300px;
  }
}