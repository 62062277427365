.pg__PlaceholderGroup {
  margin-bottom: 1rem;
  background-color: #fff;

  &__Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-weight: bold;
    color: #999;
    background-color: var(--bs-light);
    border: 1px solid #eee;
    cursor: pointer;
  }

  &__Items {
    will-change: display;

    &__Item {
      cursor: copy;
      padding: 0.5rem;

      &:hover {
        box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.05),
          0 -5px 10px 2px rgba(0, 0, 0, 0.05);
        text-decoration: underline;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }
  }
}
