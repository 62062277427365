.form-generator-radio {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-generator-bullet {
  display: list-item;
  padding-left: 0.5rem;
  margin: 0 3rem;
  font-size: 13px;
  color: #6b778c;
}