.pg-CreditCardLogo {
  width: 32px;
  margin-right: 5px;
}

.pg-PaymentItem {
  display: flex;
  justify-content: space-between;

  &__Provider {
  }
  &__ProviderTitle {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
  }
  &__ProviderFee {
    color: #888;
  }

  &__Icon {
    font-size: 18px;
    display: inline-block;
    margin-right: 10px;
  }

  &__Amount {
    text-align: right;
    font-size: 18px;
  }
  &__AmountLabel {
    text-align: right;
    color: #888;
    margin-bottom: 10px;
    font-size: 13px;
  }
  &__AmountValue {
    font-weight: bold;
  }
}

.pg-InvoiceInfo {
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #ddd;
  color: #6b778c;

  &__Title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #212c68;
    margin-bottom: 1.5rem;
  }

  &__Item {
    padding-bottom: 1rem;
  }
}
