.pg-InvoiceItem {
  display: flex;
  align-items: center;
  min-height: 38px;

  &__Actions {
    min-width: 84px;
    display: inline-flex;
    margin-left: 10px;

    .pg-Button {
      margin-left: 4px;
    }
  }

  &__Edit {
    display: flex;
  }

  &__BasicInfo {
    display: grid;
    grid-auto-flow: column;
    column-gap: 5px;
    margin-bottom: 5px;
  }

  &__EditActions {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    .pg-Button {
      margin-left: 5px;
    }
  }
}
