.pg-DashboardItem {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.08), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;

  &__Title {
    padding: 20px 20px 0;
    color: #091E42;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0;
  }

  &__Content {
    padding: 20px;
    width: 100%;
  }

  &__Empty {
    color: #5E6C84;
    font-weight: 400;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pg-DashboardTeamMembers {
  width: 100%;

  &__Header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
  }

  &__Count {
    color: #5E6C84;
    padding: 20px 20px 0;
  }

  &__Content {
    width: 100%;
    padding: 20px 20px 0;
  }

  &__Item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__ItemFlag {
    padding-right: 1rem;
  }

  &__ItemName {
    display: table-cell;
    color: #5E6C84;
    width: 100%;
  }

  &__ItemCount {
    display: table-cell;
    text-align: right;
    padding-left: 1rem;
  }

  &__BarContainer {
    display: table-cell;
  }

  &__Bar {
    width: 200px;
    border-radius: 8px;
    background: #eee;
    height: 0.5rem;
    margin-left: auto;
  }

  &__Track {
    height: 0.5rem;
    background: #13c296;
    border-radius: 8px;
  }

}

.pg-DashboardCountItem {
  width: 100%;

  &__Label {
    display: flex;
    align-items: center;
    color: #5E6C84;
    margin-bottom: 1rem;
    line-height: 16px;

    &.danger {
      color: #D0375D
    }

    &__Icon {
      margin-right: 0.5rem;
      margin-left: -0.15rem;
    }
  }

  &__Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__Count {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
    }

    &__Additional {
      color: #5E6C84;
      font-size: 14px;
      line-height: 16px;
      text-align: right;

    }
  }

}

.pg-DashboardPendingItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  &__List {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    border-top: 1px solid #EBECF0;

    & .pg-DashboardPendingItem {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 1.25rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #EBECF0;

      &__Content {
        width: 100%;
        display: flex;
        align-items: center;

        &__Icon {
          padding-right: 0.5rem;
          font-size: 1.2rem;
        }
      }



      &__Time {
        text-align: right;
        color: #5E6C84;
        font-size: 12px;
      }
    }

    &__More {
      display: inline-block;
      color: #313D7D;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0;
      margin-top: 1.25rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

  }
}