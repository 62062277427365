.pg-FormGroup {
  display: flex;
  flex-direction: column;

  &--inline {
    align-items: flex-start;
    flex-direction: row;
  }

  &__Label {
    color: #6b778c;
    font-size: 12px;
    font-weight: 600;

    &--inline {
      flex: 0 1 180px;
      margin-bottom: 0 !important;
      margin-top: 10px;
    }
  }

  &__Input {
    &--inline {
      flex: 1;
    }
  }

  &__Errors {
    padding: 5px;
    font-size: 0.9em;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .DayPickerInput {
    display: block;
  }
}
