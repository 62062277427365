@import '../../styles/helpers.scss';

.pg-LabelValueRow {
  background: #f9f9f9;
  border-radius: rem(3px);
  margin-bottom: rem(10px);
  display: flex;
  flex-direction: column;
  padding: rem(15px);

  &--inline {
    flex-direction: row;
    justify-content: space-between;
  }

  &__Label {
    color: #757575;
    white-space: nowrap;
    min-width: rem(130px);
  }

  &__Value {
    font-weight: bold;
    margin-top: rem(10px);
    word-break: break-word;
    hyphens: auto;
  }

  &--inline &__Value {
    margin-top: 0;
    margin-left: rem(10px);
  }
}
