.pg-EinInput {
  display: flex;

  &__Input {
    margin-right: .5em;
    text-align: center;

    &.is-invalid {
      padding: 0.375rem 0.75rem !important;
    }

    &--prefix {
      width: 3em;
    }

    &--number {
      width: 6em;
    }
  }
}