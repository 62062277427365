.pg-ListItem {
  display: flex;
  padding: 1rem 0;
  align-items: center;

  &__Label {
    width: 12rem;
    color: #546285;

    &--full {
      width: auto;
      flex: 1;
    }

    &--large {
      width: 15rem;

      &+.pg-ListItem__Content {
        margin-left: auto;
      }
    }

    svg {
      margin-right: 1rem;
    }
  }

  &+& {
    border-top: 1px solid #eee;
  }

  &__Edit {
    margin-left: auto;
    color: #546285;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
  }

  &__Help {
    font-size: 11px;
    margin-left: 2rem;
    max-width: 100%;
    opacity: 0.8;
  }
}