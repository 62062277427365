.pg-OnboardingRadioGroup {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-column-gap: 1.5rem;
  grid-row-gap: 0px;

  @media screen and (max-width: 500px) {
    grid-auto-flow: row;
    grid-row-gap: 1rem;
  }

  &--vertical {
    grid-auto-flow: row;
    grid-row-gap: 1rem;
  }

  &__Item {
    flex: 1;
    padding: 0.7rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    cursor: pointer;

    &--checked {
      border: 1px solid #198cca;
      background: #edf6fb;
    }

    .form-check-input:checked {
      background-color: #198cca;
      border-color: #198cca;
    }
  }

  &__ItemLabel {
    word-break: break-all;
  }
}
