.CustomQuickFilter {
  &__Wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;

    &__Search {
      width: 30%;
      height: 40px;
      margin-right: 1rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0.5rem;
      }
    }

    &__Actions {
      flex: 1;
      height: 40px;
      display: flex;
      flex-wrap: wrap;

      @media screen and (max-width: 375px) {
        height: unset;
      }

      &__Action {
        @media screen and (max-width: 1024px) {
          flex: 1;
        }

        @media screen and (max-width: 375px) {
          margin-bottom: 0.5rem;
        }

        &:not(:last-child) {
          margin-right: 1rem;

          @media screen and (max-width: 375px) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
