.pg-SsnInput {
  display: flex;

  &__Input {
    margin-right: .5em;
    text-align: center;

    &.is-invalid {
      padding: 0.375rem 0.75rem !important;
    }

    &--small {
      width: 3em;
    }

    &--medium {
      width: 4em;
    }

    &--large {
      width: 5em;
    }
  }
}