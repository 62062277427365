.pg-ContractDetails {
  &__Body {
    display: flex;
  }

  &__Content {
    border-left: 1px solid #ddd;
    min-height: 100%;
    padding: 30px 10px 0 50px;
    flex: 1;
  }

  &__Sidebar {
    flex: 0 1 250px;
    .pg-Steps {
      position: sticky;
      top: 0;
    }
  }

  &__SectionTitle {
    padding-bottom: 15px;
  }

  &__Section {
    margin-bottom: 60px;
  }
}
