@import '../../../../styles/variables.scss';

.pg-Onboarding__Steps {
	color: #A5ADBA;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 1rem;
	margin: 0;
	flex-wrap: wrap;

	@media screen and (max-width: 768px) {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
	}

	.pg-StepItem {
		display: flex;
		align-items: center;
		margin: .5rem 4.5rem;
		position: relative;
		z-index: 2;
		background: white;
		padding: 0 0.75rem;
		min-width: 170px;

		@media screen and (max-width: 1024px) {
			margin: .5rem 0;
		}

		@media screen and (max-width: 768px) {
			margin: .5rem;
		}

		&--active {
			color: $secondary;
			font-weight: 500;


		}

		&--completed {
			color: $success;
			font-weight: 500;

			.pg-StepItem__Icon {
				font-size: 16px;
			}
		}


		&__Icon {
			width: 24px;
			font-size: 18px;
			line-height: 100%;
			max-height: 24px;
			position: relative;
			text-align: center;
			margin-right: 8px;
			background-color: white;
		}

		&:not(:last-child) {

			.pg-StepItem__Icon::after {
				content: "";
				border-left: 1px dashed #A5ADBA;
				position: absolute;
				top: 100%;
				display: block;
				height: 14px;
				left: 50%;
				transform: translateX(-50%);


				@media screen and (max-width: 768px) {
					display: none;
				}
			}



			// &::after {
			// 	@media screen and (max-width: 768px) {
			// 		content: "";
			// 		border-bottom: 1px dashed #A5ADBA;
			// 		position: absolute;
			// 		top: 50%;
			// 		display: block;
			// 		height: 1px;
			// 		width: 100%;
			// 		left: 100%;
			// 		transform: translateY(-50%);
			// 		margin: 0;
			// 		z-index: 1;
			// 	}
			// }

		}
	}
}