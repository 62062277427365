@import '../../styles/helpers.scss';

.pg-RoleList {
  margin: 0 -1rem -1rem;
  &Item {
    display: flex;
    padding: rem(20px);
    border-top: rem(1px) solid #ddd;

    &__Name {
      min-width: rem(200px);
    }
  }
}
