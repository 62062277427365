@import '../../../styles/variables.scss';

.error-wrapper {
	background-color: $danger;
	text-align: start;
	color: #fff;
	padding: 1rem;
}

.success-wrapper {
	background-color: $success;
	text-align: start;
	color: #fff;
	padding: 1rem;
}



.card-num {
	font-size: 16px;
	font-weight: bold;

	.hidden-digits {
		font-size: 18px;
		margin-right: 5px;
	}
}