.pg-FileUpload {
  &__Link {
    position: relative;
  }

  &__Input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__File {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    margin: 20px 0;

    &Name {
      background: #fafbfc;
      border: 1px solid #ebecf0;
      flex: 1;
      padding: 8px 12px;
      border-radius: 5px;
      margin-right: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  ::-webkit-file-upload-button {
    cursor: pointer;
  }
}
