.pg-LabelValue {
  margin-bottom: 5px;

  &__Value {
    font-weight: bold;
  }

  &--inline {
    display: flex;
    justify-content: space-between;
  }
}
