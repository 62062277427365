.pg-Dropzone {
  border: 2px dashed #7bc5de;
  position: relative;
  text-align: center;
  padding: 20px;
  border-radius: 3px;
  margin: 20px 0;

  &__Input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &__File {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f1f1f1;
    border-radius: 3px;
    padding: 10px 20px;
    margin: 20px 0;
  }
}
