.pg-Exception {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	&__Icon {
		width: 10%;
		min-width: 100px;
		margin-bottom: 1rem;
	}

	&__Title {
		font-size: 1.5rem;
		color: #fe5d5d;
	}

	&__Content,
	&__Caption {
		max-width: 500px;
		padding: 1rem;
		margin: 0;
	}

	&__Content {
		color: #555;
		font-size: 1rem;
		text-align: center;
	}

	&__Caption {
		color: #999;
		font-size: 0.75rem;

		ul {
			list-style: "* ";
		}
	}
}