.Activities {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1;

	&__List {
		width: 100%;
		display: flex;
		flex-direction: column;
		flex: 1;
		border-top: 1px solid #EBECF0;

		&__Item {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding-top: 1.25rem;
			padding-bottom: 0.5rem;
			border-bottom: 1px solid #EBECF0;

			&__Content {
				width: 100%;
				display: flex;
				align-items: center;

				&__Label {
					line-height: 2rem;
					color: #5E6C84;

					strong {
						color: #091E42
					}

					* {
						margin-bottom: 0;
					}
				}

				&__Icon {
					padding-right: 0.5rem;
					font-size: 1.2rem;
					height: 2rem;
				}
			}



			&__Time {
				text-align: right;
				color: #5E6C84;
				font-size: 12px;
			}
		}

		&__Empty {
			color: #5E6C84;
			font-weight: 400;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__More {
			display: inline-block;
			color: #313D7D;
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 0;
			margin-top: 1.25rem;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

	}
}