@import '../../styles/variables';

html,
body,
#root {
  min-height: 100%;
}

.pg-Layout {
  height: auto;
  min-height: 100vh;
  display: flex;
  position: relative;

  &__Sidebar {
    flex: 0 1 $sidebar-width;
    width: $sidebar-width;
    display: flex;

    @media screen and (max-width: 425px) {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100vh;
      width: 100vw;
      min-width: $sidebar-width;
      z-index: 9999;
      background-color: #313d7d75;
    }

    @media screen and (min-width: 426px) and (max-width: 768px) {
      flex: 0 1 $sidebar-width-md;
      width: $sidebar-width-md;
    }
  }

  &__Content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 60px;

    .pg-Layout--has-sidebar & {
      padding: 100px 60px 60px;
      width: calc(100vw - #{$sidebar-width});

      @media screen and (max-width: 768px) {
        padding: 100px 20px 60px;
        width: calc(100vw - #{$sidebar-width-md});
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 100px 30px 30px;
    }

    @media screen and (max-width: 768px) {
      padding: 20px 20px 60px;
    }
  }
}
