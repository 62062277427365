.pg-ListCards {
  // d-flex p-4 justify-content-center align-items-center
  &__Actions:not(#{&}__Actions--align-right) {
    display: flex;
    justify-content: center;
    padding: 1rem;
    align-items: center;
  }

  &__Actions--align-right {
    overflow: hidden;
    padding-right: 1rem;
    .pg-Button {
      float: right;
    }
  }
}
