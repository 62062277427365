@import '../../styles/variables.scss';

.pg-Steps {
  display: flex;
  justify-content: space-around;
  position: relative;
  z-index: 1;
  max-width: 760px;
  margin: 10px auto 0 auto;
  flex: 1;
  width: 100%;
  color: #383c3f;

  .pg-PageTitle * + & {
    margin: -20px auto 0 auto;
  }

  &--dotted,
  &--vertical {
    flex-direction: column;
    padding-left: 30px;
  }
}

.pg-Steps--horizontal .pg-StepItem {
  $scope: '.pg-StepItem';
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &:before {
    content: '';
    width: calc(100% - 12px);
    height: 2px;
    background: #1b317a;
    position: absolute;
    left: calc(-50% + 6px);
    top: 5px;
    z-index: -1;
  }

  &:first-child:before {
    content: none;
  }

  &__Index {
    background: #1b317a;
    color: #fff;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin: auto;
    // box-shadow: 0 0 0 3px #fff, 0 0 0 4px #dedede;
  }

  &__Name {
    margin-top: 15px;
    font-weight: bold;
    font-size: 13px;
    text-transform: none;
    text-align: center;
  }

  &:nth-child(1) {
    .pg-StepItem__Index,
    &:before {
      background: linear-gradient(90deg, #2d4184 0%, #315a8b 100%);
    }

    &.pg-StepItem--active .pg-StepItem__Index {
      box-shadow: 0 0 0 3px #fff, 0 0 0 4px #2d4184;
    }
  }
  &:nth-child(2) {
    .pg-StepItem__Index,
    &:before {
      background: linear-gradient(90deg, #315a8b 0%, #367994 100%);
    }

    &.pg-StepItem--active .pg-StepItem__Index {
      box-shadow: 0 0 0 3px #fff, 0 0 0 4px #315a8b;
    }
  }
  &:nth-child(3) {
    .pg-StepItem__Index,
    &:before {
      background: linear-gradient(90deg, #367994 0%, #3b9a9d 100%);
    }

    &.pg-StepItem--active .pg-StepItem__Index {
      box-shadow: 0 0 0 3px #fff, 0 0 0 4px #367994;
    }
  }
  &:nth-child(4) {
    .pg-StepItem__Index,
    &:before {
      background: linear-gradient(90deg, #3b9a9d 0%, #3fb9a5 100%);
    }

    &.pg-StepItem--active .pg-StepItem__Index {
      box-shadow: 0 0 0 3px #fff, 0 0 0 4px #3b9a9d;
    }
  }
  &:nth-child(5) {
    .pg-StepItem__Index,
    &:before {
      background: linear-gradient(90deg, #3fb9a5 0%, #42ceab 100%);
    }

    &.pg-StepItem--active .pg-StepItem__Index {
      box-shadow: 0 0 0 3px #fff, 0 0 0 4px #3fb9a5;
    }
  }
  &:nth-child(6) {
    .pg-StepItem__Index,
    &:before {
      background: linear-gradient(90deg, #42ceab 0%, #42ceab 100%);
    }

    &.pg-StepItem--active .pg-StepItem__Index {
      box-shadow: 0 0 0 3px #fff, 0 0 0 4px #42ceab;
    }
  }

  &--active &__Index {
    box-shadow: 0 0 0 3px #fff, 0 0 0 4px #1b317a;
  }

  &--active ~ & {
    .pg-StepItem__Index,
    &:before {
      background: #dedede;
    }

    #{$scope}__Name {
      opacity: 0.7;
    }
  }

  &__Icon {
    display: none;
  }
}

.pg-Steps--vertical .pg-StepItem {
  display: flex;
  $scope: '.pg-StepItem';
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  color: #5e6c84;
  font-size: 14px;

  &__Icon,
  &__Icon svg {
    display: block;
  }

  &__Icon {
    width: 32px;
  }

  &__Name {
    margin-top: 0;
    font-weight: normal;
    font-weight: 400;
  }

  &--active #{$scope}__Name,
  &--completed #{$scope}__Name {
    font-weight: 500;
  }

  &__Index {
    display: none;
  }

  &:before,
  &:after {
    content: none;
  }
  &--active {
    color: #313d7d;
  }
  &--completed {
    color: #36cc6f;
  }
}

.pg-Steps--dotted {
  margin-top: 20px;

  .pg-StepItem {
    position: relative;
  }

  .pg-StepItem:not(:last-child) .pg-StepItem__Icon:before {
    content: '......';
    height: 20px;
    position: absolute;
    transform: rotateZ(90deg) translate(25px, -2px);
    font-size: 20px;
    color: #ddd;
    left: 0px;
    top: 0px;
    z-index: -1;
  }
}
.pg-Steps--dotted .pg-StepItem {
  display: flex;
  $scope: '.pg-StepItem';
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
  color: #5e6c84;
  font-size: 14px;

  &__Icon,
  &__Icon svg {
    display: block;
    font-size: 18px;
  }
  &__Icon svg {
    display: block;
    margin-left: -2px;
    background: #fff;
  }
  &__Icon {
    width: 32px;
  }

  &__Name {
    margin-top: 0;
    font-weight: normal;
    font-weight: 400;
  }

  &--active #{$scope}__Name,
  &--completed #{$scope}__Name {
    font-weight: 500;
  }

  &--active {
    color: #313d7d;
  }
  &--completed {
    color: #36cc6f;
  }

  .pg-Steps__NextIcon,
  .pg-Steps__ActiveIcon {
    width: 10px;
    height: 10px;
    box-shadow: 0 0 0 1px #fff, 0 0 0 2px $secondary;
    display: inline-block;
    border-radius: 10rem;
    margin-left: 3px;
  }

  .pg-Steps__ActiveIcon {
    background: $secondary;
  }

  .pg-Steps__NextIcon {
    box-shadow: 0 0 0 1px #999;
    background: #fff;
  }
}
