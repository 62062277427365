@import '../../styles/variables';

.pg-Auth {
  flex: 1;
  margin: -60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url('../../images/login-bg.svg') no-repeat top right;
  background-size: 30vw auto;

  &__Title {
    color: #fff;
    margin-top: -60px;
    margin-bottom: 30px;
  }

  &__Background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
  }

  &__Logo {
    position: absolute;
    top: 40px;
    left: 60px;
    font-size: 48px;
    font-weight: bold;
    color: $primary;
    max-width: 160px;

    img {
      max-width: 100%;
    }
  }

  &__Content {
    position: relative;
    background: #fff;
    box-shadow: 0 20px 50px -40px rgba(0, 0, 0, 0.3);
    padding: 20px;
    border-radius: 3px;
    width: 420px;

    &--large {
      width: 100%;
      max-width: 540px;
    }
  }

  &__Actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
}

.pg-AuthTabs {
  width: calc(100% + 40px);
  justify-content: space-around;
  margin: -20px -20px 30px;
  padding: 0;
  list-style-type: none;
  display: flex;

  &__Item {
    flex: 1;
    text-align: center;
    background: #f5f8f9;

    &Link {
      padding: 10px;
      display: block;

      &--active {
        background: #fff;
      }
    }
  }

  a {
    text-decoration: none;
  }
}
