@import '../../styles/variables.scss';

.pg-Preset {
  text-decoration: none;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  border: 1px solid #f5f5f5;

  &:hover {
    box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.05);
    text-decoration: none;
  }

  &__Body {
    flex: 1 1 calc(100% - 6rem);

    &__Content {
      padding: 1rem;

      &__Name {
        font-weight: bold;
        font-size: 1.1rem;
      }

      &__Description {
        color: #777;
        font-size: 0.8rem;
        margin-bottom: 0.25rem;

        .empty {
          color: #bbb;
          font-style: italic;
        }
      }
    }

    &__Footer {
      height: 50px;
      color: #aaa;
      font-size: 0.8rem;
      margin-bottom: 0;
      border-top: 1px solid #f5f5f5;
      padding: 1rem;
    }
  }

  &__Actions {
    flex: 1 1 5rem;
    border-left: 1px solid #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__Action {
      flex: 1 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #777;
      font-size: 1.5rem;
      cursor: pointer;

      &:not(.disabled):hover {
        background-color: #eee;
      }

      &.disabled {
        cursor: not-allowed;
        background-color: #f5f5f5;
        user-select: none;
        pointer-events: all;
      }

      &.Danger {
        height: 50px;
        font-size: 1.25rem;
        flex: 0 0 auto;
        border-top: 1px solid #f5f5f5;

        &:not(.disabled):hover {
          color: white;
          background-color: $danger;
        }
      }
    }
  }
}
