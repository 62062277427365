.pg-OnboardingFormGroup {
  &__Form {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 2rem;
  }

  &__Title {
    font-size: 1.2rem;
    font-weight: 500;
  }

  & + & {
    border-top: 1px solid #eee;
    margin-top: 2rem;
    padding-top: 2rem;
  }
}
