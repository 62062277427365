.pg-ApprovalBox {
  background: #fafbfc;
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #091e42;
  padding: 2rem;
  margin-bottom: 1rem;

  &__Total {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 1.5rem;
  }

  &__Icon {
    color: #7a869a;
    margin-right: 0.5rem;
  }

  p {
    margin: 0;
  }
}
