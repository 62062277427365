.pg-Box {
  background-color: #fff;
  border-radius: 4px;

  &--elevated {
    box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.05);
    padding: 20px;
  }

  &--clickable {
    cursor: pointer;
    padding: 20px;
  }

  &--bordered {
    border: 2px solid #eee;
  }

  &--selected {
    border: 2px solid #36cc6f;
    background: rgba(#36cc6f, 0.1);
  }
}
