@import '../../styles/variables';

.pg-Login {
  &__Actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
}
