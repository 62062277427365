@import '../../styles/variables';

.pg-SidebarItem {
  font-weight: 400;
  display: block;
  padding: 6px 10px 6px 20px;
  margin: 4px 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-radius: 10px;
  color: #fff;
  transition: 0.15s all ease-in;
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 426px) and (max-width: 768px) {
    padding: .5rem;
    margin: .75rem;
  }

  &.--disabled,
  &.--disabled &__Icon,
  &.--disabled:hover {
    color: #5A6496 !important;
    cursor: not-allowed;
    background: transparent !important;
  }


  &--active,
  &:hover {
    background: rgba(rgb(0, 0, 0), 0.2);
    color: #fff;
    text-decoration: none;
  }

  &--active &__Icon,
  &:hover &__Icon {
    color: #fff;
  }

  &__Icon {
    transition: 0.15s all ease-in;
    margin-right: 20px;
    min-width: 16px;
    font-size: 24px;
    margin-top: -2px;
    color: #fff;

    @media screen and (min-width: 426px) and (max-width: 768px) {
      margin: 0 auto;
    }
  }

  &__Text {
    @media screen and (min-width: 426px) and (max-width: 768px) {
      display: none;
    }
  }

  &__ToggleIcon {
    font-size: 18px;
    margin-left: auto;

    @media screen and (min-width: 426px) and (max-width: 768px) {
      display: none;
    }
  }

  &__Accordion {
    @media screen and (min-width: 426px) and (max-width: 768px) {
      background: #273164;
      margin: 0 0.75rem;
      border-radius: 10px;
    }
  }

  &__Accordion & {
    @media screen and (min-width: 769px) {

      &__Icon {
        display: none;
      }

      &:before {
        content: '';
        position: absolute;
        width: 2px;
        height: calc(100% + 8px);
        background: #5a6497;
        left: -14px;
      }

      & {
        margin: 4px 20px 4px 64px;
        min-height: 46px;
      }

      &:last-child:before {
        background: transparent;
        width: 14px;
        height: 26px;
        top: 0;
        border: 2px solid #5a6497;
        border-top: none;
        border-right: none;
        border-radius: 0 0 0 10px;
      }

      &:after {
        content: '';
        width: 12px;
        height: 2px;
        background: #5a6497;
        position: absolute;
        left: -12px;
      }

      &:last-child:after {
        content: none;
      }
    }


  }
}