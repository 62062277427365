.pg-AddTemplateButton__Redirect {
  border-top: 1px solid #f5f5f5;
  margin-top: 2.5rem;
  padding: 1.5rem;
  text-align: center;
  position: relative;

  &::before {
    content: 'OR';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    background: white;
    padding: 0 1rem;
    color: #999;
    font-size: 12px;
  }
}

.pg-PresetGroup__Templates__AddNew {
  width: 160px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px dotted #f5f5f5;
  color: #999;
  user-select: none;
  font-size: 0.9rem;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background: #f9f9f9;
    cursor: pointer;
    border: none;
  }

  svg {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
}
