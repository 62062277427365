.pg-FormSection {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;

  &__Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(240, 240, 240);
  }

  &__Title {
    margin-bottom: 0;
    font-size: 20px;
  }

  &__Info {
    color: #999;
    font-size: 16px;
  }

  &__FooterActions {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid rgb(240, 240, 240);
  }
}