.pg-TextArea {
  position: relative;
  display: flex;
  flex-direction: column;

  textarea {
    padding-bottom: 32px;
  }

  &__Count {
    margin-left: auto;
    margin-right: 20px;
    font-weight: bold;
    font-size: 0.8em;
    color: #757575;
    margin-top: -24px;
    position: relative;
    pointer-events: none;
    background: #fff;
  }
}
