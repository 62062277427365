@import '../../styles/variables.scss';

.pg-RouterTabItem {
  display: flex;
  text-decoration: none;
  color: #777;
  transition: 0.1s all ease-in;
  position: relative;
  padding: 10px 20px 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 0.025rem;
  border: 1px solid transparent;
  border-radius: 100px;
  margin-right: 1rem;

  &:hover,
  &--active {
    text-decoration: none;
    color: darken($primary, 15%);
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 3px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    transition: 0.1s width ease-in;
  }

  &:hover::before {
    background: darken($light, 20%);
    width: 100%;
  }

  &--active:hover:before,
  &--active:before {
    background: $primary;
    width: 100%;
  }
}
