/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.pg-checkout-form {
	text-align: center;

	button {
		float: right;
	}

	.info {
		font-size: 0.8rem;
		text-align: start;
		color: #333;
		background-color: aliceblue;
		display: block;
		padding: 1rem;
		margin: 2rem 0;
	}
}