.pg-CurrencySelectContainer {
  position: relative;
  display: flex;
  & .pg-Input {
    padding-left: 36px;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  &__Symbol {
    position: absolute;
    top: 8px;
    left: 12px;
  }

  & .pg-ReactSelect {
    flex: 0 0 100px;
    margin-left: -1px;
  }

  & .pg-CurrencySelect__control {
    border-radius: 0 4px 4px 0;
  }
}
