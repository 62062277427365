.pg-ShowPreset {
  &__Groups {
    &__New {
      height: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 3px dotted #f5f5f5;
      color: #999;
      user-select: none;
      font-size: 0.9rem;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        background: #f9f9f9;
        cursor: pointer;
        border: none;
      }

      svg {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
