@import '../../styles/variables.scss';

.pg-ToasterContainer {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10000;
}

.pg-Toaster {
  border-radius: 0;
  margin-bottom: 20px;

  &--primary {
    border-left: 8px solid $primary;
    .pg-Toaster__Title {
      color: $primary;
    }
  }
  &--success {
    border-left: 8px solid $success;
    .pg-Toaster__Title {
      color: $success;
    }
  }
  &--danger {
    border-left: 8px solid $danger;
    .pg-Toaster__Title {
      color: $danger;
    }
  }
  &--info {
    border-left: 8px solid $info;
    .pg-Toaster__Title {
      color: $info;
    }
  }
  &--warning {
    border-left: 8px solid $warning;
    .pg-Toaster__Title {
      color: $warning;
    }
  }

  &__Title {
    svg {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}
