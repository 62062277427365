.pg-Button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 38px;
  min-width: 38px;
  border-radius: 5px;

  &--loading {
    color: transparent !important;
  }

  &__RightContent {
    margin-left: 10px;
    display: inline-flex;
  }

  &__LeftContent {
    margin-right: 10px;
    display: inline-flex;
  }

  svg {
    font-size: 1.2em;
  }

  &.btn-primary {
    color: #fff;
  }

  &.btn-outline-light {
    color: #091e42;
    border-color: #dfe1e6;
  }

  .btn:disabled,
  .btn.disabled,
  fieldset:disabled .btn {
    opacity: 0.5;
  }

  &.no-outline-focus {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.pg-ButtonLoader {
  display: inline-block;
  width: 2em;
  height: 1em;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  color: #fff;

  .btn-link & {
    color: #6c757d;
  }
}

.pg-ButtonLoader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 1em;
  height: 1em;
  margin: 0.5em;
  border: 0.2em solid currentColor;
  border-radius: 50%;
  animation: pg-ButtonLoader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}

.pg-ButtonLoader div:nth-child(1) {
  animation-delay: -0.45s;
}

.pg-ButtonLoader div:nth-child(2) {
  animation-delay: -0.3s;
}

.pg-ButtonLoader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes pg-ButtonLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}