@import '../../../styles/variables.scss';

.bp4-tab-list {
  .bp4-tab {
    font-weight: bold;
    color: $secondary;
    text-align: center;
    width: 50%;
    outline: none;
    margin-right: 0 !important;
    padding: 1rem;
  }
}

.bp4-control.bp4-switch {
  input:checked,
  input:focus {
    outline: none !important;

    ~ .bp4-control-indicator {
      outline: none !important;
    }
  }
}

.bp4-control.bp4-switch
  input:checked
  ~ .bp4-control-indicator
  .bp4-control-indicator-child:first-child {
  transform: initial;
}
.bp4-control.bp4-switch .bp4-control-indicator-child:first-child,
.bp4-control.bp4-switch
  input:checked
  ~ .bp4-control-indicator
  .bp4-control-indicator-child:last-child {
  transform: scale(0);
}
