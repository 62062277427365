.pg-StepSectionContainer {
  padding: 30px;

  &--no-padding {
    padding: 0;
  }

  &__RightColumn--centered {
    margin-top: -60px;
  }
}
