.pg-ContractorToggle {
  margin: 15px 60px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 9999px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 426px) and (max-width: 768px) {
    flex-direction: column;
    margin: 5px 15px;
  }

  &::before {
    content: 'Toggle account type';
    position: absolute;
    bottom: 100%;
    margin: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: none;
    padding: 10px;
    border-radius: 10px;
    width: 200px;
    text-align: center;
  }

  &:hover::before {
    display: block;
  }

  &__Item {
    padding: 10px;
    font-size: 26px;
    flex: 1;
    text-align: center;

    @media screen and (min-width: 426px) and (max-width: 768px) {
      font-size: 18px;
    }

    &--active {
      color: #24346a;
      background: #fff;
      border-radius: 9999px;
    }
  }
}