.pg-ErrorState {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;

  &__Icon {
    font-size: 64px;
    color: #de9a33;
  }
  &__Title {
  }
  &__Subtitle {
  }
  &__Content {
  }
}
