.pg-InvoiceDetails {
  position: relative;

  &.pg-Box {
    padding: 2rem;
    background: #fafbfc;
    border-radius: 5px;
  }

  &__Icon {
    color: #7a869a;
    font-size: 1.2rem;
  }

  &__Title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #6b778c;
    border-bottom: 1px solid #ebecf0;
    padding-bottom: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  &__Close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
  }
}
