$primary: #13c296;
$secondary: #2e4286;
$tertiary: #5e1d92;
$success: #17c671;
$info: #00b8d8;
$warning: #ffb400;
$danger: #c4183c;
$light: #6b778c;
$dark: #172b4d;
$white: #ffffff;

$colors: (primary: $primary,
  secondary: $secondary,
  tertiary: $tertiary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  white: $white,
);

.pg-Text {

  @each $name,
  $value in $colors {
    &--#{$name} {
      color: $value;
    }
  }

  &--bold {
    font-weight: bold;
  }

  &--underline {
    text-decoration: underline;
  }

  &--italic {
    font-style: italic;
  }

  &--align-left {
    text-align: left;
  }

  &--align-center {
    text-align: center;
  }

  &--align-right {
    text-align: right;
  }

  &--break {
    word-wrap: break-word;
  }

  &--ellipsis {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
  }
}