.pg-TaxForm {
  margin: 30px 0 0;

  &__Section {
    margin-top: 2rem;
  }

  &__Type {
    text-align: center;
    text-transform: uppercase;
  }

  &__Instructions {
    font-style: italic;
    font-size: 13px;
    margin-bottom: 2rem;
  }

  &__SectionTitle {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .modal-footer {
    margin: 1rem -1rem -1rem;
  }
}


.pg-TaxInfo {
  display: flex;
  margin: 0 -1rem;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 55vh;
  padding: 1px 0;

  &__Item {
    border: 1px solid #ddd;
    border-top: none;
    flex: 0 0 50%;
    min-height: 140px;
    margin: 0 -1px -1px 0;
    padding: 20px;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:last-child {
      border-bottom: none;
    }
  }
}