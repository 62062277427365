.c-Templates {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1;

	&__Title {
		width: 100%;
		color: #091E42;
		font-weight: bold;
		font-size: 18px;
		line-height: 20px;
		padding-bottom: 1.25rem;
		margin-bottom: 0;
	}

	&__List {
		width: 100%;
		display: flex;
		flex-direction: column;
		flex: 1;

		table {
			thead {
				th {
					font-style: normal;
					font-weight: bold;
					font-size: 12px;
					line-height: 16px;
					color: #6B778C;
					padding: 1.2rem 0;
					border-bottom: 1px solid #EBECF0;
				}
			}

			tbody {
				tr {
					border-bottom: 1px solid #EBECF0;
				}

				td {
					padding: 1.125rem 0;
					line-height: 20px;

					&.date {
						color: #6B778C;
					}

					&.amount {
						color: #172B4D;
					}

					&.pdf span {
						color: #313D7D;
						text-transform: capitalize;
						text-decoration: underline;
						cursor: pointer;
					}
				}
			}
		}

		& .c-DashboardPendingItem {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding-top: 1.25rem;
			padding-bottom: 0.5rem;
			border-bottom: 1px solid #EBECF0;

			&__Content {
				width: 100%;
				display: flex;
				align-items: center;

				&__Icon {
					padding-right: 0.5rem;
					font-size: 1.2rem;
				}
			}



			&__Time {
				text-align: right;
				color: #5E6C84;
				font-size: 12px;
			}
		}

		&__More {
			display: inline-block;
			color: #313D7D;
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 0;
			margin-top: 1.25rem;
			cursor: pointer;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

	}
}