@import '../../styles/variables';

.pg-Sidebar__CloseBtn {
  display: none;

  @media screen and (max-width: 425px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    background: #303e7d;
    padding: 0.75rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 32px;
    padding-bottom: 14px;
    cursor: pointer;
  }
}

.pg-Sidebar {
  flex: 1;
  background-color: #313d7d;
  background-position: 50% 150%;
  background-repeat: no-repeat;
  background-size: auto 1000px;
  position: fixed;
  top: 0;
  bottom: 0;
  width: $sidebar-width;
  display: flex;
  flex-direction: column;
  color: #fff;
  overflow: visible;
  z-index: 20;

  @media screen and (min-width: 426px) and (max-width: 768px) {
    width: $sidebar-width-md;
  }

  @media screen and (max-width: 425px) {
    width: 80vw;
    min-width: $sidebar-width;
  }

  &__Logo {
    font-size: 38px;
    display: flex;
    text-decoration: none;
    font-weight: bold;
    padding: 20px 40px;

    @media screen and (min-width: 426px) and (max-width: 768px) {
      padding: 10px;
    }

    &Link {
      display: block;
      max-width: 160px;

      &,
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }

    &Image {
      max-width: 100%;
    }
  }

  &__CompanySelector {
    margin: 0 2rem 1rem;

    @media screen and (min-width: 426px) and (max-width: 768px) {
      margin: 0 0.75rem 1rem;
    }
  }

  &__Content {
    overflow-y: auto;
    flex: 1;
  }

  &__Footer {
    background-color: #313d7d;
    width: 100%;
  }

  .dropdown-menu {
    width: calc(100% - 20px);
    margin-left: 10px;

    @media screen and (min-width: 426px) and (max-width: 768px) {
      width: 200px !important;
    }

    a {
      display: block;
      color: $secondary;
      text-decoration: none;
      cursor: pointer;
      padding: 10px 20px;
    }
  }

  &__Divider {
    height: 54px;
  }
}

.pg-UserMenu {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  cursor: pointer;

  &:hover {
    background-color: rgba(rgb(0, 0, 0), 0.2);
  }

  @media screen and (min-width: 426px) and (max-width: 768px) {
    padding: 10px;
  }

  &__Name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media screen and (min-width: 426px) and (max-width: 768px) {
      display: none;
    }
  }

  svg {
    font-size: 38px;
    margin-right: 10px;

    @media screen and (min-width: 426px) and (max-width: 768px) {
      margin: 0 auto;
    }
  }
}
