.text-tertiary {
  color: $tertiary;
}


/**
* Genrate utility classes for text color
* .text-{$color}
*/
@mixin color-modifiers {

  @each $name,
  $hex in $colors {
    &-#{$name} {
      color: $hex;
    }
  }
}