@import '../../styles/variables.scss';

.pg-RouterTabs {
  display: flex;
  flex-direction: column;

  &__Items {
    // border-bottom: 1px solid darken($light, 10%);
    display: flex;
    margin-bottom: 2em;
  }
}
