.pg-ContractDetailsHeader {
  font-weight: normal;

  &__Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__Title {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 0;
  }
}