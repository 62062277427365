.pg-PageTitle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: #fff;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 19;

  &--bordered {
    border-bottom: 1px solid #ebecf0;
  }

  &__Heading {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #091e42;
    display: flex;
    flex-direction: column;
    line-height: normal;
    margin-bottom: 0;
    flex: 1;
  }

  &__Subtitle {
    color: #999;
    font-weight: 600;
  }

  &__Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 0 60px;

    &__SidebarToggle {
      cursor: pointer;
      margin-right: -0.5rem;
    }

    @media screen and (max-width: 1024px) {
      padding: 0 30px;
    }

    @media screen and (max-width: 768px) {
      padding: 0 20px;
    }
  }

  &__Action {
    display: flex;
    align-items: center;
  }
}
