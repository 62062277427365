.Page_Onboarding {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .Content {
    margin-right: 300px;
    max-width: calc(100% - 300px);

    @media screen and (max-width: 1024px) {
      margin-right: 200px;
      max-width: calc(100% - 200px);
    }

    @media screen and (max-width: 768px) {
      margin-right: auto;
      max-width: 100%;
    }
  }

  .Navigation {
    width: 300px;
    border-left: 1px solid #dfe1e6;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    padding-top: 80px;
    z-index: 1;

    @media screen and (max-width: 1024px) {
      width: 200px;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      position: relative;
      border-left: none;
      right: unset;
      top: unset;
      bottom: unset;
      padding-top: 0;
    }
  }
}

.App_Logo {
  width: 200px;
  height: 70px;

  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.pg-Onboarding {
  &__Header &__Steps {
    flex-direction: row;

    .pg-StepItem {
      min-width: initial;
      margin: 1rem;
    }

    .pg-StepItem:not(:last-child) .pg-StepItem__Icon::after {
      content: none;
    }
  }
}
