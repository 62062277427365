.pg-MediaObject {
  display: flex;
  align-items: flex-start;

  &--align-center {
    align-items: center;
  }

  &--align-bottom {
    align-items: flex-end;
  }

  &__Figure {
    margin-right: 1em;
  }

  &__Body {
    flex: 1;
  }
}
