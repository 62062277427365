.pg-RadioBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: relative;
  border: 1px solid #eee;
  padding: 4rem 2rem;
  border-radius: 4px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    padding: 2rem;
  }

  &--checked {
    border: 1px solid #096ab0;
    box-shadow: 0 5px 10px 0 rgba($color: #000000, $alpha: 0.05);
  }

  &__Input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  img {
    margin-bottom: 2rem;
    max-width: 80px;
  }
}