.pg-Tag {
  font-size: 12px;
  border-radius: 20px;
  padding: 2px 8px;
  // font-weight: bold;
  // border: 1px solid currentColor;
  // text-transform: uppercase;
  color: currentColor;
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  white-space: nowrap;
  vertical-align: middle;

  &+& {
    margin-left: 5px;
  }

  &--status {
    &-default {
      color: #42526e;
    }

    &-warning {
      color: rgb(224, 133, 14);
    }

    &-primary {
      color: rgb(24, 77, 173);
    }

    &-secondary {
      color: rgb(153, 18, 180);
    }

    &-success {
      color: rgb(29, 148, 39);
    }

    &-danger {
      color: rgb(168, 28, 28);
    }
  }

  &--size {
    &-small {
      font-size: 11px;
      padding: 1px 6px 1px 6px;
    }

    &-large {
      font-size: 13px;
      padding: 4px 12px 3px 12px;
    }
  }

  &__Content {
    display: inline-flex;
    align-items: center;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: currentColor;
      opacity: 0.2;
      border-radius: 20px;
    }
  }

  &__Icon {
    font-size: 10px;
    margin-right: 5px;
    transform: translateY(-1px);
    display: inline-block;
  }
}