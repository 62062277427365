.pg-SignaturePad {
	display: inline-flex;
	border-radius: 8px;
	border: 2px dashed #ddd;
	position: relative;
	margin-bottom: 20px;
	max-width: 100%;

	&__Placeholder {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__Clear {
		position: absolute;
		top: 0;
		right: 0;
	}

	&__Canvas {
		width: 360px;
		height: 127px;

		&--small {
			width: 250px;
			height: 100px;
		}

		canvas {
			width: 100%;
			height: 100%;
		}
	}

	&--empty &__Canvas {
		opacity: 0.1;
	}
}

.pg-SignatureText {

	&__Containment,
	&__Canvas {
		width: 360px;
		height: 127px;

		&--small {
			width: 250px;
			height: 100px;
		}
	}

	&__Canvas {
		border-radius: 8px;
		border: 2px dashed #ddd;
	}

	&__Containment {
		position: fixed;
		top: -1000px;
		left: -1000px;
		opacity: 0;
		pointer-events: none;
	}

	&__Content {
		white-space: nowrap;
		font-family: 'Kristi';
		font-size: 48px;
	}

	&__Input {
		width: 360px;
	}
}