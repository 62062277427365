$primary: #13c296;
$secondary: #2e4286;
$tertiary: #5e1d92;
$success: #17c671;
$info: #00b8d8;
$warning: #ffb400;
$danger: #c4183c;
$light: #e9ecef;
$dark: #383c3f;

$colors: (primary: $primary,
  secondary: $secondary,
  tertiary: $tertiary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
);

$font-family-sans-serif: 'Inter',
system-ui,
-apple-system,
'Segoe UI',
Roboto,
'Helvetica Neue',
Arial,
'Noto Sans',
'Liberation Sans',
sans-serif,
'Apple Color Emoji',
'Segoe UI Emoji',
'Segoe UI Symbol',
'Noto Color Emoji';
$headings-font-family: 'Inter',
system-ui,
-apple-system,
'Segoe UI',
Roboto,
'Helvetica Neue',
Arial,
'Noto Sans',
'Liberation Sans',
sans-serif,
'Apple Color Emoji',
'Segoe UI Emoji',
'Segoe UI Symbol',
'Noto Color Emoji';
$font-size-root: 14px;

$body-color: $dark;
$headings-color: $secondary;
$link-color: $secondary;

$sidebar-width: 250px;
$sidebar-width-md: 80px;