@import '../../styles/variables.scss';

.pg-PresetGroup {
  margin-bottom: 1rem;
  border: 1px solid #f5f5f5;

  &__Name {
    margin: 0;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__Content {
      .title {
        margin-bottom: 0.25rem;
        display: flex;
        align-items: center;

        > * {
          margin-bottom: 0;
        }
      }

      .description {
        margin-bottom: 0.25rem;
        font-weight: normal;
        color: #999;
        font-size: 0.8rem;
      }
    }

    &__Tag {
      font-weight: normal;
    }
  }

  &__Notes {
    padding: 1rem;
    font-size: 0.75rem;
    color: #aaa;
  }

  &__Templates {
    gap: 1rem;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
  }

  &__Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f5f5f5;

    &__Options {
      padding: 1rem;
      display: flex;
      align-items: center;

      &__Option {
        &:not(:first-child) {
          padding-left: 1rem;
        }

        &:not(:last-child) {
          padding-right: 1rem;
          border-right: 1px solid #f5f5f5;
        }
      }
    }

    &__Actions {
      display: flex;
      align-items: center;

      &__Action {
        padding: 1rem;
        cursor: pointer;
        width: 50px;
        text-align: center;
        border-left: 1px solid #f5f5f5;
        font-size: 1.25rem;
        flex: 0 0 auto;

        &:not(.disabled):hover {
          background-color: #eee;
        }

        &.disabled {
          cursor: not-allowed;
          background-color: #f5f5f5;
          user-select: none;
          pointer-events: all;
        }

        &.Danger {
          &:not(.disabled):hover {
            color: white;
            background-color: $danger;
          }
        }
      }
    }
  }
}
