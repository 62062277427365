.pg-Compliance {
  &__UseContractToggle {
    display: flex;

    &__Button {
      flex: 1;
      &:hover,
      input:checked + & {
        color: #fff !important;
      }
      &:first-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -1px;
      }
      &:last-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
      }
    }
  }

  &__CheckList {
    &Item {
      padding-bottom: 10px;
    }
  }
}

.pg-ContractFileUpload {
  background: url('../../../images/upload-placeholder.svg') no-repeat center
    20px #fafbfc;
  border: 1px dashed #dfe1e6;
  border-radius: 5px;
  position: relative;
  text-align: center;
  padding: 80px 20px 20px;
  border-radius: 3px;
  margin: 20px 0;

  &__Input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &__File {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f1f1f1;
    border-radius: 3px;
    padding: 10px 20px;
    margin: 20px 0;
  }
}
