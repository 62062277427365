.pg-ComplianceModal {
  .modal-footer {
    .pg-Button:first-child {
      margin-right: auto;
    }
  }
}


.pg-ComplianceSection {
  padding: 0;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #EEE;

  &__Title {
    padding: 1rem;
    margin: 0;
    background: #FAFAFA;
    border-bottom: 1px solid #EEE;
  }

  &__Content {
    padding: 1rem 1rem 0 1rem;

    &__Empty {
      text-align: center;
      color: #999;
      margin: 1rem 0;
      font-style: italic;
    }
  }

}