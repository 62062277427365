.secret {
	position: relative;

	.obscure {
		filter: blur(7px);
	}

	.show_link {
		display: flex;
		color: #333;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		text-decoration: underline;
		cursor: pointer;
		transform: translate(-50%, -50%);
		padding: 0.5rem;
	}
}